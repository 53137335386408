"use strict"
import Vue from 'vue'
const Helper = {
    install(Vue) {
        Vue.prototype.appName = process.env.VUE_APP_NAME
        Vue.prototype.appNameNormal = process.env.VUE_APP_NAME_NORMAL
        Vue.prototype.appNameDetail = process.env.VUE_APP_NAME_DETAIL
        Vue.prototype.appNameUNG = process.env.VUE_APP_NAME_UNG
        Vue.prototype.getImage = function (image) {
            if (image != null && image.length > 0) {
                return process.env.VUE_APP_BACKEND_URL + "/assets/uploads/products/" + image
                // return image
            }
            return "/img/unavailable.png"
        }
        Vue.prototype.getAvatar = function (image) {
            if (image != null && image.length > 0) {
                return process.env.VUE_APP_BACKEND_URL + "/assets/uploads/photos/" + image
                // return image
            }
            return "/img/unavailable.png"
        }
        Vue.prototype.getIcon = function (image) {
            if (image != null && image.length > 0) {
                return process.env.VUE_APP_BACKEND_URL + "/assets/uploads/categories/" + image
                // return image
            }
            return "/img/unavailable.png"
        }
    }
}
Vue.use(Helper)