import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../i18n";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Home.vue"),
      },

      // BERANDA
      {
        path: "jumlah-mahasiswa/:id",
        name: "Detail Mahasiswa Fakultas",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/fakultas/DetailMahasiswaFakultas.vue"
          ),
      },
      {
        path: "jumlah-mahasiswa/jurusan/:id",
        name: "Detail Mahasiswa Jurusan",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/fakultas/DetailMahasiswaJurusan.vue"
          ),
      },

      // LAYANAN KEMENTERIAN
      {
        path: "kementerian/simkatmawa",
        name: "SIMKATMAWA",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/Simkatmawa.vue"
          ),
      },
      {
        path: "kementerian/selancar_pak",
        name: "SELANCAR PAK",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/Selancar_pak.vue"
          ),
      },

      // SISTER
      {
        path: "kementerian/sister",
        name: "SISTER",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/sister/Sister.vue"
          ),
      },
      {
        path: "kementerian/sister/sertifikasi-dosen",
        name: "SISTER SERTIFIKASI DOSEN",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/sister/Sertifikasi_dosen.vue"
          ),
      },
      {
        path: "kementerian/sister/pendidikan-pengajaran",
        name: "SISTER PENDIDIKAN PENGAJARAN",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/sister/Pendidikan_pengajaran.vue"
          ),
      },
      {
        path: "kementerian/sister/penelitian-publikasi",
        name: "SISTER PENELITIAN PUBLIKASI",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/sister/Penelitian_publikasi.vue"
          ),
      },
      {
        path: "kementerian/sister/penelitian-publikasi/:id",
        name: "SISTER PENELITIAN PUBLIKASI DETAIL",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/sister/Penelitian_publikasi_detail.vue"
          ),
      },
      {
        path: "kementerian/sister/pengabdian-masyarakat",
        name: "SISTER PENGABDIAN MASYARAKAT",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/sister/Pengabdian_masyarakat.vue"
          ),
      },
      {
        path: "kementerian/sister/pengabdian-masyarakat/:id",
        name: "SISTER PENGABDIAN MASYARAKAT DETAIL",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/sister/Pengabdian_masyarakat_detail.vue"
          ),
      },
      // END SISTER

      {
        path: "kementerian/sapto_sapta",
        name: "SAPTO/SAPTA",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/Sapto_sapta.vue"
          ),
      },

      // PDDIKTI
      {
        path: "kementerian/pddikti",
        name: "PDDIKTI",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/pddikti/Pddikti.vue"
          ),
      },
      // END PDDIKTI

      {
        path: "kementerian/pin_sivil",
        name: "PIN/SIVIL",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/Pin_sivil.vue"
          ),
      },
      {
        path: "kementerian/sinta",
        name: "SINTA",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/Sinta.vue"
          ),
      },
      {
        path: "kementerian/simlitabmas",
        name: "SIMLITABMAS",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/Simlitabmas.vue"
          ),
      },
      {
        path: "kementerian/simtendik",
        name: "SIMTENDIK",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/Simtendik.vue"
          ),
      },
      {
        path: "kementerian/laporkerma",
        name: "LAPORKERMA",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/Laporkerma.vue"
          ),
      },
      {
        path: "kementerian/siaga",
        name: "SIAGA",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/kementerian/Siaga.vue"
          ),
      },

      // DATA AKADEMIK
      {
        path: "akademik/jumlah-mahasiswa",
        name: "Jumlah Mahasiswa",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/akademik/JumlahMahasiswa.vue"
          ),
      },
      {
        path: "akademik/status-mahasiswa",
        name: "Status Mahasiswa",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/akademik/StatusMahasiswa.vue"
          ),
      },
      {
        path: "akademik/sebaran-mahasiswa",
        name: "Sebaran Mahasiswa",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/akademik/SebaranMahasiswa.vue"
          ),
      },
      {
        path: "akademik/lulusan-mahasiswa",
        name: "Lulusan Mahasiswa",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/akademik/LulusanMahasiswa.vue"
          ),
      },

      // DATA PEGAWAI
      {
        path: "pegawai/statistik-pegawai",
        name: "Statistik Pegawai",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/pegawai/StatistikPegawai.vue"
          ),
      },

      // DATA IKU
      {
        path: "iku/iku-1",
        name: "IKU 1",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/iku/Iku1.vue"),
      },
      {
        path: "iku/iku-2",
        name: "IKU 2",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/iku/Iku2.vue"),
      },
      {
        path: "iku/iku-3",
        name: "IKU 3",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/iku/Iku3.vue"),
      },
      {
        path: "iku/iku-4",
        name: "IKU 4",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/iku/Iku4.vue"),
      },
      {
        path: "iku/iku-5",
        name: "IKU 5",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/iku/Iku5.vue"),
      },
      {
        path: "iku/iku-6",
        name: "IKU 6",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/iku/Iku6.vue"),
      },
      {
        path: "iku/iku-7",
        name: "IKU 7",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/iku/Iku7.vue"),
      },
      {
        path: "iku/iku-8",
        name: "IKU 8",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/iku/Iku8.vue"),
      },

      // AKREDITAS
      {
        path: "akreditas",
        name: "Akreditas",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/akreditas/Akreditas.vue"
          ),
      },
      {
        path: "akreditas/:id/jenjang/:jenjang",
        name: "Akreditas By Id",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/akreditas/AkreditasById.vue"
          ),
      },
      {
        path: "akreditas/peringkat/:id",
        name: "Akreditas By Peringkat",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/akreditas/AkreditasByPeringkat.vue"
          ),
      },

      // MENU
      {
        path: "menu/:slug",
        name: "menu",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Menus.vue"),
      },

      // ROUTES ADMIN
      // PEGAWAI
      //
      {
        path: "about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "categories",
        name: "categories",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Categories.vue"),
      },
      {
        path: "products",
        name: "products",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Products.vue"),
      },
      {
        path: "category/:slug",
        name: "category",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Category.vue"),
      },
      {
        path: "product/:slug",
        name: "product",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Product.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// tambahkan ini untuk melakukan pengecekan pada setiap routing
router.beforeEach((to, from, next) => {
  // jika routing ada meta auth-nya maka
  if (to.matched.some((record) => record.meta.auth)) {
    // jika user adalah guest
    if (store.getters["auth/guest"]) {
      // tampilkan pesan bahwa harus login dulu
      store.dispatch("alert/set", {
        status: true,
        text: "Login first",
        color: "error",
      });
      // tampilkan form login
      store.dispatch("dialog/setComponent", "login");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
