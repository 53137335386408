import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/helper';
import ReadMore from 'vue-read-more';
import {
  LoadingPlugin
} from "vuetify-loading-overlay"
import VueApexCharts from 'vue-apexcharts'
import JsonExcel from "vue-json-excel";
import lineClamp from 'vue-line-clamp'
import i18n from './i18n'
import VueCarousel from 'vue-carousel';

Vue.use(VueCarousel);

Vue.use(lineClamp, {
  // plugin options
})

Vue.component("downloadExcel", JsonExcel);
Vue.use(LoadingPlugin, {
  //props
  spinnerProps: {},
  overlayProps: {},
});

// APEXCHART
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// READMORE
Vue.use(ReadMore);

// VUE MOMENT
const moment = require('moment')
require('moment/locale/id')

Vue.use(require('vue-moment'), {
  moment
})

Vue.config.productionTip = false

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {

  // use the langague from the routing param or default language
  let language = to.params.lang;
  if (!language) {
    language = 'id'
  }

  // set the current language for i18n
  i18n.locale = language
  next()
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')