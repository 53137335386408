<template>
  <v-app>
    <v-navigation-drawer app v-model="sidebar" clipped>
      <div class="text-center">
        <img class="mt-5 mb-3" src="/img/logo/logo-blue.png" height="50" />
      </div>
      <v-divider></v-divider>
      <!-- <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
          color="primary"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
      </v-list> -->
      <v-list dense>
        <v-list-item to="/" color="primary">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n4">
            <v-list-item-title>{{ $t("beranda") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-for="item in menuDropdown"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="`/${$i18n.locale}` + child.route"
          >
            <v-list-item-content class="ml-n4">
              <v-list-item-title>{{ $t(child.title) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item :to="`/${$i18n.locale}/akreditas`" color="primary">
          <v-list-item-icon>
            <v-icon>mdi-seal-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n4">
            <v-list-item-title>{{
              $t("akreditas.akreditas")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark flat>
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click="sidebar = !sidebar"> </v-app-bar-nav-icon>
      </span>
      <img class="mr-2 hidden-xs-only" src="/img/logo/logo-white.png" height="50" />
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <div class="locale-changer mt-3" style="width: 80px">
          <v-select
            v-model="$i18n.locale"
            :items="langs"
            item-text="text"
            item-value="id"
            solo
            flat
            light
            dense
            v-on:change="langChange"
          ></v-select>
        </div>
      </v-toolbar-items>
      <template #extension class="hidden-xs-only">
        <v-toolbar
          color="secondary"
          rounded
          dark
          floating
          class="hidden-xs-only"
        >
          <v-btn text to="/" color="white">
            <v-icon left dark>mdi-home</v-icon>
            {{ $t("beranda") }}
          </v-btn>

          <v-menu
            open-on-hover
            bottom
            offset-y
            v-for="(item, index) in menuDropdown"
            :key="`menu1-` + index"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="white" text dark v-bind="attrs" v-on="on">
                <v-icon left dark>{{ item.action }}</v-icon>
                {{ $t(item.title) }}
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item
                link
                v-for="child in item.items"
                :key="child.title"
                :to="`/${$i18n.locale}` + child.route"
              >
                <v-list-item-title>{{ $t(child.title) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn text :to="`/${$i18n.locale}/akreditas`" color="white">
            <v-icon left dark>mdi-seal-variant</v-icon>
            {{ $t("akreditas.akreditas") }}
          </v-btn>
        </v-toolbar>
        <!-- <v-toolbar
          color="white"
          rounded
          dark
          floating
          class="hidden-sm-and-up justify-center"
        >
          <img src="/img/logo/logo-blue.png" height="50" />
        </v-toolbar> -->
      </template>
    </v-app-bar>

    <v-main class="bgcolor">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer padless color="white">
      <v-col class="text-center" cols="12"
        ><span class="text-body-2">
          Copyright &copy; 2022 <strong>{{ appNameDetail }}</strong
          >. All right reserved.
        </span>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
//import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",
  data() {
    return {
      appTitle: "INFOGRAFIS",
      sidebar: false,

      langs: [
        { id: "id", text: "ID" },
        { id: "en", text: "EN" },
      ],

      menuDropdown: [
        {
          action: "mdi-office-building-cog",
          items: [
            {
              title: "kementerian.simkatmawa",
              route: "/kementerian/simkatmawa",
            },
            {
              title: "kementerian.selancar_pak",
              route: "/kementerian/selancar_pak",
            },
            {
              title: "kementerian.sister",
              route: "/kementerian/sister",
            },
            {
              title: "kementerian.sapto_sapta",
              route: "/kementerian/sapto_sapta",
            },
            {
              title: "kementerian.pddikti",
              route: "/kementerian/pddikti",
            },
            {
              title: "kementerian.pin_sivil",
              route: "/kementerian/pin_sivil",
            },
            {
              title: "kementerian.sinta",
              route: "/kementerian/sinta",
            },
            {
              title: "kementerian.simlitabmas",
              route: "/kementerian/simlitabmas",
            },
            {
              title: "kementerian.simtendik",
              route: "/kementerian/simtendik",
            },
            {
              title: "kementerian.laporkerma",
              route: "/kementerian/laporkerma",
            },
            {
              title: "kementerian.siaga",
              route: "/kementerian/siaga",
            },
          ],
          title: "kementerian.layanan",
        },
      ],
    };
  },
  methods: {
    langChange: function(e) {
      // console.log(e);

      // this.dialogLoading = true;
      this.$i18n.locale = e;
      this.$router.push({
        params: { lang: e },
      });
    },
  },
};
</script>
<style></style>
